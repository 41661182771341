import * as React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import { Button } from '../components/globalElements';

const NotFound = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 5rem 0rem;

  > * {
    padding: 20px 0px;
  }

  h1 {
    font-size: 3rem;
  }

  p {
    max-width: 500px;
    text-align: center;
    line-height: 1rem;
  }

  .button-block {
    display: inline-flex;
    gap: 20px;
  }
`;

const NotFoundPage = () => {
  return (
    <Layout title='Page Not Found'>
      <NotFound>
        <h1>Page Not Found</h1>
        <p>
          Unfortunately the content you're looking for isn't here. There may be
          a misspelling in your web address or you may have clicked a link for
          content that no longer exists.
        </p>

        <div className='button-block'>
          <Button to='/'>Go Home</Button>
          <Button to='/all-tiles'>Explore All Tiles</Button>
        </div>
      </NotFound>
    </Layout>
  );
};

export default NotFoundPage;
